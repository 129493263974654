import React, { useState, useEffect, useRef } from "react";
import Chip from "@mui/material/Chip";
import './ConoFuturo.css';

const getChipColor = (key, parentKey) => {
    const lowerParentKey = parentKey.toLowerCase();
    if (key.toLowerCase().includes("ambicioso")) return "#EFCE2A";
    if (lowerParentKey.includes("adyacentes") || lowerParentKey.includes("adyacentesii")) return "#A4CBF4";
    if (lowerParentKey.includes("altamente")) return "#E592BA";
    if (lowerParentKey.includes("directos")) return "#64CD84";
    return "#ccc";
};

const AnimatedDetails = ({ children }) => {
    return (
        <div className="container-animated">
            <div className="background-blue">
                {children}
            </div>
        </div>
    );
};

const convertJsonToTreeNodes = (json, updateValue, removeChip, selectedChip, setSelectedChip, path = [], addChip, visibleScenario, selectedChipRef, isMobile) => {
    const sectionOrder = ["Puesto", "EscenariosDirectos", "EscenariosAdyacentes", "EscenariosAltamenteDivergentes"];

    const traverse = (obj, path, parentKey = "") => {

        const sortedEntries = Object.entries(obj).sort(([keyA], [keyB]) => {
            const indexA = sectionOrder.indexOf(keyA);
            const indexB = sectionOrder.indexOf(keyB);

            if (indexA === -1 && indexB === -1) return 0;
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;
            return indexA - indexB;
        });

        return sortedEntries.map(([key, value], index, array) => {
            const currentPath = [...path, key];
            const chipColor = getChipColor(key, parentKey);
            const isSelected = selectedChip?.join(".") === currentPath.join(".");
            const siblingCount = path.length > 0 ? Object.keys(obj).length : 1;

            if (
                (key === "EscenariosDirectos" && visibleScenario !== "directos") ||
                (key === "EscenariosAdyacentes" && visibleScenario !== "adyacentes") ||
                (key === "EscenariosAdyacentesII" && visibleScenario !== "adyacentesii") ||
                (key === "EscenariosAltamenteDivergentes" && visibleScenario !== "altamente")
            ) {
                return null;
            }

            if (typeof value === "object" && value !== null) {
                return (
                    <li key={currentPath.join(".")} className="animated-li">
                        <AnimatedDetails summaryText={key}>
                        <ul className={`ul-node ${isMobile ? "mobile-list" : ""}`}>
                            {traverse(value, currentPath, key)}
                            <DropdownButton
                                chipColor={chipColor}
                                addChip={(type) => addChip(currentPath, type)}
                                currentCount={Object.keys(value).length}
                            />
                            </ul>
                        </AnimatedDetails>
                    </li>
                );
            }

            const isPuestoNode = key.toLowerCase().includes("puesto");
            const handleChipClick = (path) => {
                setSelectedChip((prev) => (prev?.join(".") === path.join(".") ? null : path));
            };
            
            return (
                <li ref={isSelected ? selectedChipRef : null}
                    key={currentPath.join(".")}
                    className="animated-li-container"
                >
                    {isPuestoNode && (
                        <div className="puesto-text">
                            Puesto
                        </div>
                    )}

                    {index < array.length - 1 && !isPuestoNode && (
                        <div className="lines line-long"> </div>
                    )}

                    {index === array.length - 1 && !isPuestoNode && (
                        <div className="lines line-short ">
                            <div className="arrowhead-line"></div>
                        </div>
                    )}

                    {isPuestoNode && array.length > 1 && (
                        <div
                            className="lines"
                            style={{              
                                top: isMobile ? "4rem" : "2rem", 
                            }}
                        >
                        </div>
                    )}

                    <div className="ul-space">
                        {!isPuestoNode && isSelected && siblingCount > 1  && (
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removeChip(currentPath);
                                    setSelectedChip(null);
                                }}
                                className="remove-button">
                                ✕
                            </button>
                        )}
                        <Chip
                            label={
                                <input
                                    type="text"
                                    value={value}
                                    maxlength="60"
                                    onClick={() => handleChipClick(currentPath)}
                                    className="is-puesto-node"
                                    style={{
                                        padding: isPuestoNode ? "28px" : "8px",
                                    }}
                                    onChange={(e) => updateValue(currentPath, e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            updateValue(currentPath, e.target.value);
                                            e.target.blur();
                                        }
                                    }}
                                />
                            }
                            variant="filled"
                            className="filled-styles"
                            style={{
                                backgroundColor: chipColor,
                                color: chipColor === "#ccc" ? "#000" : "#fff",
                                border: isSelected ? "2px solid blue" : "",
                            }}
                        />
                    </div>
                </li>
            );
        });
    };

    return traverse(json, path);
};


const DropdownButton = ({ chipColor, addChip, currentCount }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleButtonClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setIsOpen((prev) => {
            const newState = !prev;
            return newState;
        });
    };


    useEffect(() => {
        console.log("Estado de isOpen actualizado:", isOpen);
    }, [isOpen]);

    return (
        <div className="plusbutton" ref={dropdownRef}>
            {currentCount < 8 && (

                <button className="buttonstyle"
                    style={{
                        color: chipColor === "#ccc" ? "#000" : "#fff",
                        transform: "translateY(27px);",
                        fontSize: "18px",
                    }}
                    onClick={(e) => {
                        handleButtonClick(e);
                    }}
                >
                    +
                </button>
            )}
            <div
                className="escenario-add"
                style={{
                    opacity: isOpen ? 1 : 0,
                    transform: isOpen ? "translateY(0)" : "translateY(-20px)",
                    pointerEvents: isOpen ? "auto" : "none",
                }}
            >
                <button

                    onClick={(e) => {
                        e.preventDefault();
                        addChip("escenario");
                        setIsOpen(false);
                    }}

                    className="add-escenario-text"
                    
                    onMouseEnter={(e) => {
                        e.target.style.color = "#FFFFFF";
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.color = "#C6C7C8";
                    }}
                >
                    Agregar Escenario
                </button>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        addChip("ambicioso");
                        setIsOpen(false);
                    }}
                    
                    className="add-ambicioso-text"

                    onMouseEnter={(e) => {
                        e.target.style.color = "#EFCE2A";
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.color = "#C6C7C8";
                    }}
                >
                    Agregar Esc.Ambicioso
                </button>
            </div>
        </div>
    );
};

const ConoFuturo = ({ data, onSave, visibleScenario }) => {
    const [localData, setLocalData] = useState(data);
    const [selectedChip, setSelectedChip] = useState(null);
    const selectedChipRef = useRef(null); 
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const updateValue = (path, newValue) => {
        const update = (obj, keys) => {
            if (keys.length === 1) {
                obj[keys[0]] = newValue;
            } else {
                if (!obj[keys[0]]) obj[keys[0]] = {}; 
                update(obj[keys[0]], keys.slice(1));
            }
        };
    
        // Clonar `localData` para actualizarlo sin mutarlo directamente
        const newData = JSON.parse(JSON.stringify(localData)); 
        update(newData, path);
        setLocalData(newData);
        onSave(newData);
    };    

    const addChip = (path, type) => {
        const update = (obj, keys) => {
            if (keys.length === 1) {
                const entries = Object.keys(obj[keys[0]]);
                let newKey = "";
                if (type === "ambicioso") {
                    const ambiciosoKeys = entries.filter((k) =>
                        k.toLowerCase().includes("ambicioso")
                    );
                    newKey = `Ambicioso ${ambiciosoKeys.length + 1}`;
                } else {
                    const regularKeys = entries.filter(
                        (k) => !k.toLowerCase().includes("ambicioso")
                    );
                    newKey = `${regularKeys.length + 1}`;
                }
                obj[keys[0]][newKey] = "";
            } else {
                update(obj[keys[0]], keys.slice(1));
            }
        };
        const newData = { ...localData };
        update(newData, path);
        setLocalData(newData);
    };

    const removeChip = (path) => {
        const update = (obj, keys) => {
            if (keys.length === 1) {
                if (obj[keys[0]] !== undefined) {
                    delete obj[keys[0]];
                }
            } else {
                update(obj[keys[0]], keys.slice(1));
                if (Object.keys(obj[keys[0]]).length === 0) {
                    delete obj[keys[0]];
                }
            }
        };
    
        const newData = JSON.parse(JSON.stringify(localData)); 
        update(newData, path);
        setLocalData(newData);
        onSave(newData);
        setSelectedChip(null);
    };     

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectedChipRef.current && !selectedChipRef.current.contains(event.target)) {
                setSelectedChip(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div style={{ maxHeight: "none", overflowY: "visible", padding: "16px", backgroundColor: "#005280", borderRadius: "0 0 15px 15px", overflow: "hidden" }}>
            <ul className = "ul-node" >
                {convertJsonToTreeNodes(localData, updateValue, removeChip, selectedChip, setSelectedChip, [], addChip, visibleScenario, selectedChipRef)}
            </ul>
        </div>
    );
};

export default ConoFuturo;