import axios from "axios";

import "./ProyectoDeAprendizaje.css";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

import { ThreeDots } from "react-loader-spinner";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

import { EmptySidebar } from "../../components";
import ProyectoAprendizaje from "../ProyectoAprendizaje/ProyectoAprendizaje";
import Tendencias from "../Tendencias/Tendencias";
import Escenarios from "../Escenarios/Escenarios";

import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ConoFuturoImage from "../../components/ConoFuturo/ConoFuturoPA/ConoFuturoPA";
import Modal from '@mui/material/Modal';


const ProyectoDeAprendizaje = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [dataFirst, setDataFirst] = useState(null);
  const [dataSecond, setDataSecond] = useState(null);
  const [dataThird, setDataThird] = useState(null);
  const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const jsonData = {
    Puesto: "",
    EscenariosAltamenteDivergentes: {
      1: "",
    },
    EscenariosDirectos: {
      1: "",
    },
    EscenariosAdyacentes: {
      1: "",
    },
    EscenariosAdyacentesII: {
      1: "",
    },
  };

  const [savedData, setSavedData] = useState(jsonData);

  const handleSave = (updatedData) => {
    setSavedData(updatedData);
  };

  const url = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "http://localhost:3501";

  const getData = async () => {
    setLoading(true);
    setError(false);

    const email = authUser?.email.replace(/"/g, "");
    try {
      const [firstResponse, secondResponse, thirdResponse] = await Promise.all([
        axios.get(`${url}/answers/get-learning-project-first/${email}`),
        axios.get(`${url}/answers/get-learning-project-second-temp/${email}`),
        axios.get(`${url}/answers/get-learning-project-third/${authUser?.id}/${email}`),
      ]);

      if (firstResponse.data.data?.message === "Datos encontrados") {
        setDataFirst(firstResponse.data.data);
      }

      if (secondResponse.data.data) {
        setDataSecond(secondResponse.data.data);
      }

      if (thirdResponse.data.data) {
        setDataThird(thirdResponse.data.data);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(true); // Marca un error si alguna solicitud falla
    } finally {
      setLoading(false); // Marca loading como falso después de todas las solicitudes
    }
  };

  const sendLearningProjectEmail = async () => {
    try {
      const response = await fetch(
        "http://localhost:3501/auth/send-learning-project-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error sending email:", errorText);
        alert("Error: " + errorText);
        return;
      }

      const result = await response.json();
      alert("Success: " + result.message);
    } catch (error) {
      console.error("Network error:", error);
      alert("Network error: Unable to send email. Please try again later.");
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      /*if (authUser.current_module !== 'ffc340f4-eea3-4ab5-8430-d65fb062a6e8') {
                window.location.href = '/miespacio';
                return
            }*/

      getData();
    }
  }, [authUser, isLoggedIn]);

  useEffect(() => {
    const storedUser = Cookies.get("authUser");
    if (!isLoggedIn && storedUser) {
      const user = JSON.parse(storedUser);
      if (user && user.token) {
        setAuthUser(user);
        setIsLoggedIn(true);
      } else {
        setAuthUser(null);
        setIsLoggedIn(false);
        navigate("/login");
      }
    } else if (!isLoggedIn) {
      navigate("/login");
    }

    if (isLoggedIn) {
      /*if (authUser?.current_module !== 'ffc340f4-eea3-4ab5-8430-d65fb062a6e8') {
                window.location.href = '/miespacio';
                return;
            }
            */
      getData();
    }
  }, []);

  const options = [
    {
      id: 0,
      name: "Mi propósito de vida",
      icon: <ArrowForwardIosRoundedIcon />,
      onClick: () => setCurrentTab(0),
    },
    {
      id: 1,
      name: "Tendencias aspiraciones profesionales",
      icon: <ArrowBackIosRoundedIcon />,
      onClick: () => setCurrentTab(1),
    },
    {
      id: 2,
      name: "Escenarios",
      icon: <ArrowBackIosRoundedIcon />,
      onClick: () => setCurrentTab(2),
    },
  ];

  const content = (currentTab) => {
    if (loading) {
      return (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="var(--primary)"
          ariaLabel="three-dots-loading"
        />
      );
    }

    if (error || (dataFirst === null && currentTab === 0)) {
      return (
        <div className="error-container">
          <p className="error-title">Parece que no se cargó tu información correctamente.</p>
          <p className="error-details">
            Por favor revisa tus respuestas para ver el contenido aquí.
          </p>
        </div>
      );
    }

    switch (currentTab) {
      case 0:
        return dataFirst ? (
          <ProyectoAprendizaje aprendizajeData={dataFirst} />
        ) : (
          <div className="error-container">
            <p className="error-title">Parece que no se cargó tu información correctamente.</p>
            <p className="error-details">
              Por favor revisa tus respuestas para ver el contenido aquí.
            </p>
          </div>
        );
      case 1:
        return dataSecond ? (
          <Tendencias tendenciasData={dataSecond} />
        ) : (
          <div className="error-container">
            <p className="error-title">Parece que no se cargó tu información correctamente.</p>
            <p className="error-details">
              Por favor revisa tus respuestas para ver el contenido aquí.
            </p>
          </div>
        );
      case 2:
        return <RenderConoFuturo dataThird={dataThird} />;
      default:
        return null;
    }
  };

  const RenderConoFuturo = ({ dataThird }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showCono, setShowCono] = useState(false);

    useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth <= 768);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (isMobile) {
      return dataThird && dataThird.Puesto ? (
        <>
          <button
            onClick={() => setShowCono(true)}
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              backgroundColor: '#005280',
              color: 'white',
              border: 'none',
              borderRadius: '5px'
            }}>
            Ver Cono de Futuro
          </button>
          <Modal
            open={showCono}
            onClose={() => setShowCono(false)}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100vw',  
              maxWidth: '100%',  
              height: '100vh', 
              overflow: 'hidden', 
            }}
          >
            <div className="cono-scroll-wrapper">
              <div className="cono-container">
                <ConoFuturoImage data={dataThird} mobile={isMobile} />
              </div>
            </div>
          </Modal>
        </>
      ) : dataThird ? (
        <Escenarios escenariosData={dataThird} />
      ) : (
        <div className="error-container">
          <p className="error-title">Parece que no se cargó tu información correctamente.</p>
          <p className="error-details">
            Por favor revisa tus respuestas para ver el contenido aquí.
          </p>
        </div>
      );
    } else {
      if (dataThird && dataThird.Puesto) {
        return <ConoFuturoImage data={dataThird} mobile = {isMobile} />
      }
      else if (dataThird.message === "Hay datos") {
        return <Escenarios escenariosData={dataThird} />
      } else {
        return (
          <div className="error-container">
            <p className="error-title">Parece que no se cargó tu información correctamente.</p>
            <p className="error-details">
              Por favor revisa tus respuestas para ver el contenido aquí.
            </p>
          </div>
        );
      }
    }
  };

  return (
    <>
      <div className="aprendizaje-layout">
        <div className="col-25">
          <EmptySidebar
            currentOption={currentTab}
            options={options}
            title="Proyecto de Aprendizaje"
          />
        </div>
        <div className="col-75" id="section-to-print">
          {content(currentTab)}
        </div>
      </div>
    </>
  );
};

export default ProyectoDeAprendizaje;
