import "./Form.css";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { createTheme, FormLabel, ThemeProvider } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dropdown from "../Dropdown/Dropdown";
import TextGroup from "../TextGroup/TextGroup";
import TableInput from "../TableInput/TableInput";
import FileUpload from "../FileUpload/FileUpload";
import CheckboxGroup from "../CheckboxGroup/CheckboxGroup";
import { useRef, useState, useEffect } from "react";
import ConodeFuturo from "../ConoFuturo/ConodeFuturo";

export default function Form(props) {
  const formAnswers = useRef([]);
  const [visibleSubQuestions, setVisibleSubQuestions] = useState({});
  const [error, setError] = useState(false);
  const [formAnswersState, setFormAnswersState] = useState([
    ...formAnswers.current,
  ]);

  const theme = createTheme({
    typography: {
      fontFamily: ["ibm plex sans", "Roboto"].join(","),
      fontSize: 13,
      fontWeight: 400,
    },
  });

  const mentor_key = [
    "claudia-ortega",
    "ana-diz",
    "thatiana-villanueva",
    "lilian-saavedra",
    "monica-ferrara",
    "lucia-salas",
  ];

  useEffect(() => {
    if (props.formAnswers && props.formAnswers.length > 0) {
      formAnswers.current = [...props.formAnswers];
      setFormAnswersState([...props.formAnswers]);
    } else {
      formAnswers.current = [];
      setFormAnswersState([]);
    }

    const updatedSurvey = props.survey.map(q => ({
      ...q,
      questionLinked: q.questionLinked?.map(subq => ({
        ...subq,
        answer: formAnswers.current.find(a => a.id_question === subq.id_question)?.answer || subq.answer || ""
      })) || []
    }));

    setFormAnswersState(prevState => [...prevState, ...updatedSurvey.flatMap(q => q.questionLinked || [])]);

  }, [props.formAnswers, props.survey]);



  const findParentAnswer = (id) => {
    if (formAnswersState.length === 0) {
      const allSurveyQuestions = props.survey.flatMap(q => [q, ...(q.questionLinked || [])]);
      return allSurveyQuestions.find(q => q.id_question === id)?.answer || "";
    }

    const answerFromState = formAnswersState.find(pq => pq.id_question === id)?.answer;
    if (answerFromState) return answerFromState;

    const allQuestions = props.survey.flatMap(q => [q, ...(q.questionLinked || [])]);

    return allQuestions.find(q => q.id_question === id)?.answer || "";
  };

  const getAnswerForQuestion = (id_question) => {
    const answerFromState = formAnswersState.find(a => a.id_question === id_question)?.answer;
    if (answerFromState !== undefined) return answerFromState;

    const allQuestions = props.survey.flatMap(q => [q, ...(q.questionLinked || [])]);
    return allQuestions.find(q => q.id_question === id_question)?.answer || "";
  };

  useEffect(() => {
    if (!props.survey || props.survey.length === 0) return;

    const newVisibleSubQuestions = { ...visibleSubQuestions };

    props.survey.forEach(q => {
      if (q.questionLinked) {
        q.questionLinked.forEach(subq => {

          const parentQuestion = props.survey.find(q =>
            q.questionLinked?.some(subqItem => subqItem.id_question === subq.id_question)
          );

          const parentAnswer = findParentAnswer(parentQuestion?.id_question);
          const expectedValue = String(subq.parentChoice?.value);

          if (newVisibleSubQuestions[subq.id_question] === false && expectedValue !== String(parentAnswer)) {
            return;
          }

          newVisibleSubQuestions[subq.id_question] = expectedValue === String(parentAnswer);

        });
      }
    });

    if (JSON.stringify(visibleSubQuestions) !== JSON.stringify(newVisibleSubQuestions)) {
      setVisibleSubQuestions(newVisibleSubQuestions);
    }
  }, [props.survey]);

  const blockId = props.block;
  const userId = props.user;

  if (formAnswers.current.length > 0) {
    if (formAnswers.current[0].block_id !== blockId) {
      formAnswers.current = [];
    }
  }

  const singleCallback = (answer) => {
    const index = formAnswers.current.findIndex(
      (element) => element.id_question === answer.id_question
    );

    if (index > -1) {
      formAnswers.current[index].answer = answer.answer;
      props.callback(formAnswers.current);
      setFormAnswersState([...formAnswers.current]);
    } else {
      formAnswers.current.push(answer);
      setFormAnswersState([...formAnswers.current]);
    }

    props.callback(formAnswers.current);
  };

  const onAnswer = (event, type, id, label, key_validation) => {
    const value = event.target.value;

    if (error) setError(false);

    if (key_validation === "true" && !mentor_key.includes(value)) {
      setError(true);
      return;
    }

    const index = formAnswers.current.findIndex(a => a.id_question === id);

    if (index !== -1) {
      formAnswers.current[index].answer = value;
    } else {
      // Si es una nueva respuesta, la agrega
      formAnswers.current.push({
        block_id: blockId,
        user_id: userId,
        answer: value,
        question_type: type || "Cono de Futuro",
        id_question: id,
        question: label || "Cono de Futuro",
      });
    }

    setFormAnswersState(prevState => {
      const updatedState = prevState.map(a =>
        a.id_question === id ? { ...a, answer: value } : a
      );

      return updatedState.some(a => a.id_question === id)
        ? updatedState
        : [...updatedState, { id_question: id, answer: value }];
    });

    const parentQuestion = props.survey.find(q => q.id_question === id);

    if (parentQuestion?.questionLinked) {
      setVisibleSubQuestions(prev => {
        const updated = { ...prev };
        parentQuestion.questionLinked.forEach(subq => {
          const expectedValue = String(subq.parentChoice?.value);
          updated[subq.id_question] = expectedValue === String(value);
        });
        return updated;
      });
    }

    props.callback([...formAnswers.current]);
  };


  const onCheckbox = (event, type, id, label) => {
    const uniqueAnswers = Array.from(
      new Map(
        formAnswers.current.map((answer) => [answer.id_question, answer])
      ).values()
    );

    formAnswers.current = uniqueAnswers;

    const index = formAnswers.current.findIndex(
      (element) => element.id_question === id
    );

    if (index > -1) {
      if (event.target.checked) {
        if (!formAnswers.current[index].answer.includes(event.target.value)) {
          formAnswers.current[index].answer.push(event.target.value);
        }
      } else {
        const checkboxIndex = formAnswers.current[index].answer.indexOf(
          event.target.value
        );
        if (checkboxIndex > -1) {
          formAnswers.current[index].answer.splice(checkboxIndex, 1);
        }

        if (formAnswers.current[index].answer.length === 0) {
          formAnswers.current.splice(index, 1);
        }
      }
    } else {
      formAnswers.current.push({
        block_id: blockId,
        user_id: userId,
        answer: [event.target.value],
        question_type: type,
        id_question: id,
        question: label,
      });
    }

    // Filtrar duplicados después de cada actualización
    formAnswers.current = formAnswers.current.filter(
      (answer, idx, self) =>
        idx === self.findIndex((a) => a.id_question === answer.id_question)
    );

    // Llama al callback para sincronizar el estado
    props.callback([...formAnswers.current]);
  };

  const questions = [];
  props.survey
    .map(q => {
      const cleanedQuestion = { ...q };
      if (Array.isArray(cleanedQuestion.questionLinked) && cleanedQuestion.questionLinked.length === 0) {
        delete cleanedQuestion.questionLinked;
      }
      return cleanedQuestion;
    })
    .forEach(q => {
      debugger
      if (q != null) {
        let options;
        if (q.questionLinked) {
          questions.push(
            <FormControl key={q.id_question}>
              <FormLabel>{q.question_label}</FormLabel>
              <RadioGroup
                value={formAnswersState.find((a) => a.id_question === q.id_question)?.answer || q.answer || ""}
                onChange={(event) => {
                  onAnswer(event, q.question_type, q.id_question, q.question_label);
                }}
              >
                {q.choices.map((choice) => (
                  <FormControlLabel
                    key={choice.id}
                    control={<Radio />}
                    label={choice.choice_label}
                    value={choice.choice_label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          );

          q.questionLinked.forEach((subq) => {
            if (visibleSubQuestions[subq.id_question]) {
              questions.push(
                <FormControl key={subq.id_question}>
                  <FormLabel
                    sx={{
                      paddingBottom: subq.question_type === "matrix" ? "12px" : "4px",
                      fontSize: subq.question_type === "matrix" ? "1.1rem" : "1rem",
                      fontWeight: subq.question_type === "matrix" ? "bold" : "normal",
                    }}
                  >
                    {subq.question_label}
                  </FormLabel>

                  {subq.question_type === "text" ? (
                    <TextField
                      id={subq.id_question}
                      variant="outlined"
                      value={getAnswerForQuestion(subq.id_question)}
                      onChange={(event) =>
                        onAnswer(event, subq.question_type, subq.id_question, subq.question_label)
                      }
                    />
                  ) : subq.question_type === "matrix" && visibleSubQuestions[subq.id_question] ? (
                    <>
                      <FormControl component="fieldset">
                        {subq.choices.map((choice) => (
                          <div key={choice.id}>
                            <FormLabel component="legend">{choice.label}</FormLabel>
                            <RadioGroup
                              row
                              value={getAnswerForQuestion(`${subq.id_question}-${choice.id}`) || ""}
                              onChange={(event) =>
                                onAnswer(
                                  { target: { value: event.target.value } },
                                  subq.question_type,
                                  `${subq.id_question}-${choice.id}`,
                                  choice.label
                                )
                              }
                            >
                              {[1, 2, 3, 4, 5].map((num) => (
                                <FormControlLabel
                                  key={num}
                                  value={String(num)}
                                  control={<Radio />}
                                  label={String(num)}
                                />
                              ))}
                            </RadioGroup>
                          </div>
                        ))}
                      </FormControl>
                    </>
                  ) : (
                    <RadioGroup
                      value={formAnswersState.find((a) => a.id_question === subq.id_question)?.answer || ""}
                      onChange={(event) => {
                        onAnswer(event, subq.question_type, subq.id_question, subq.question_label);
                      }}
                    >
                      {subq.choices.map((choice) => (
                        <FormControlLabel
                          key={choice.id}
                          control={<Radio />}
                          label={choice.label}
                          value={choice.label}
                        />
                      ))}
                    </RadioGroup>
                  )}
                </FormControl>
              );
            }
          });
        }
        debugger
        if (!q.questionLinked) {
          console.log("Pregunta sin respuesta linkeada")
          switch (q.question_type) {
            case "radio_single_choice":
              options = [];
              q.choices.forEach((op) => {
                if (op.category_dependency_input !== null) {
                  const formAnswersStateVar = [
                    ...new Set([...props.survey, ...formAnswersState]),
                  ];
                  if (
                    formAnswersStateVar.find(
                      (element) =>
                        element.id_question === op.category_dependency_input
                    )?.answer !== op.cateogry_dependency_answer
                  ) {
                    return;
                  }
                }
                const hasNewAnswer = formAnswersState.find(
                  (element) =>
                    element.id_question === q.id_question &&
                    element.answer !== q.answer
                );
                if (q.answer === op.choice_label && !hasNewAnswer) {
                  // Selected
                  options.push(
                    <FormControlLabel
                      index={q.id_question}
                      control={<Radio checked />}
                      label={op.choice_label}
                      value={op.choice_label}
                    />
                  );
                } else {
                  options.push(
                    <FormControlLabel
                      control={<Radio />}
                      label={op.choice_label}
                      value={op.choice_label}
                    />
                  );
                }
              });
              questions.push(
                <FormControl component="fieldset">
                  {q.question_description && (
                    <label className={"questionTitle"}>
                      <b>{q.question_description}</b>
                    </label>
                  )}

                  <FormLabel component="span" sx={{ whiteSpace: "pre-line" }}>
                    {q.question_label}
                  </FormLabel>
                  <RadioGroup
                    onChange={(event) => {
                      onAnswer(
                        event,
                        q.question_type,
                        q.id_question,
                        q.question_label
                      );
                    }}
                  >
                    {options}
                  </RadioGroup>
                </FormControl>
              );
              break;
            case "text":
              questions.push(
                <FormControl>
                  {q.question_title && (
                    <label className={"questionTitle"}>
                      <b>{q.question_title}</b>
                    </label>
                  )}
                  {q.question_description && (
                    <label className={"questionTitle"}>
                      <b>{q.question_description}</b>
                    </label>
                  )}
                  <FormLabel component="span" sx={{}}>
                    {q.question_label}
                  </FormLabel>
                  <TextField
                    id={q.id_question}
                    variant="outlined"
                    defaultValue={q.answer}
                    onChange={(event) =>
                      onAnswer(
                        event,
                        q.question_type,
                        q.id_question,
                        q.question_label
                      )
                    }
                  />
                </FormControl>
              );
              break;
            case "text-small":
              questions.push(
                <FormControl sx={{ width: "40ch" }}>
                  {q.question_title && (
                    <label className={"questionTitle"}>
                      <b>{q.question_title}</b>
                    </label>
                  )}
                  {q.question_description && (
                    <label className={"questionTitle"}>
                      <b>{q.question_description}</b>
                    </label>
                  )}
                  <FormLabel component="span" sx={{ whiteSpace: "pre-line" }}>
                    {q.question_label}
                  </FormLabel>
                  <TextField
                    id={q.id_question}
                    variant="outlined"
                    defaultValue={q.answer}
                    onChange={(event) =>
                      onAnswer(
                        event,
                        q.question_type,
                        q.id_question,
                        q.question_label
                      )
                    }
                  />
                </FormControl>
              );
              break;
            case "text-large":
              questions.push(
                <FormControl>
                  {q.question_title && (
                    <label className={"questionTitle"}>
                      <b>{q.question_title}</b>
                    </label>
                  )}
                  {q.question_description && (
                    <label className={"questionTitle"}>
                      <b>{q.question_description}</b>
                    </label>
                  )}
                  <FormLabel component="span" sx={{ whiteSpace: "pre-line" }}>
                    {q.question_label}
                  </FormLabel>
                  <TextField
                    id={q.id_question}
                    variant="outlined"
                    multiline
                    defaultValue={q.answer}
                    onChange={(event) =>
                      onAnswer(
                        event,
                        q.question_type,
                        q.id_question,
                        q.question_label
                      )
                    }
                  />
                </FormControl>
              );
              break;
            case "textarea":
              questions.push(
                <FormControl>
                  {q.question_title && (
                    <label className={"questionTitle"}>
                      <b>{q.question_title}</b>
                    </label>
                  )}
                  {q.question_description && (
                    <label className={"questionTitle"}>
                      <b>{q.question_description}</b>
                    </label>
                  )}
                  <FormLabel component="span" sx={{ whiteSpace: "pre-line" }}>
                    {q.question_label}
                  </FormLabel>
                  <TextField
                    id={q.id_question}
                    variant="outlined"
                    multiline
                    defaultValue={q.answer}
                    onChange={(event) =>
                      onAnswer(
                        event,
                        q.question_type,
                        q.id_question,
                        q.question_label
                      )
                    }
                  />
                </FormControl>
              );
              break;
            case "file_upload":
              questions.push(
                <FormControl>
                  {q.question_description && (
                    <label className={"questionTitle"}>
                      <b>{q.question_description}</b>
                    </label>
                  )}
                  <FormLabel component="span" sx={{ whiteSpace: "pre-line" }}>
                    {q.question_label}
                  </FormLabel>
                  <FileUpload
                    answer={q.answer}
                    fileCallback={singleCallback}
                    userData={{
                      id: userId,
                      block: blockId,
                      id_question: q.id_question,
                      question: q.question_label,
                    }}
                  />
                </FormControl>
              );
              break;
            case "radio_range":
              if (q?.choices) {
                questions.push(
                  <FormControl>
                    {q.question_description && (
                      <label className={"questionTitle"}>
                        <b>{q.question_description}</b>
                      </label>
                    )}
                    <FormLabel>{q.question_label}</FormLabel>
                    <RadioGroup
                      row
                      defaultValue={+q.answer}
                      onChange={(event) =>
                        onAnswer(
                          event,
                          q.question_type,
                          q.id_question,
                          q.question_label
                        )
                      }
                    >
                      {q.choices.map((_, i) => (
                        <FormControlLabel
                          key={i}
                          value={i + 1}
                          control={<Radio />}
                          label={i + 1}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                );
              } else {
                if (q.length === 5) {
                  questions.push(
                    <FormControl>
                      <FormLabel>{q.question_label}</FormLabel>
                      <RadioGroup
                        row
                        defaultValue={+q.answer}
                        onChange={(event) =>
                          onAnswer(
                            event,
                            q.question_type,
                            q.id_question,
                            q.question_label
                          )
                        }
                      >
                        {[...Array(5)].map((_, i) => (
                          <FormControlLabel
                            key={i}
                            value={i + 1}
                            control={<Radio />}
                            label={i + 1}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  );
                } else {
                  questions.push(
                    <FormControl>
                      <FormLabel>{q.question_label}</FormLabel>
                      <RadioGroup
                        row
                        defaultValue={q.answer}
                        onChange={(event) =>
                          onAnswer(
                            event,
                            q.question_type,
                            q.id_question,
                            q.question_label
                          )
                        }
                      >
                        {[...Array(10)].map((_, i) => (
                          <FormControlLabel
                            key={i}
                            value={i + 1}
                            control={<Radio />}
                            label={i + 1}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  );
                }
              }

              break;
            case "checkbox_multiple_choice":
              const ans = JSON.parse(q.answer);
              formAnswers.current.push({
                block_id: blockId,
                user_id: userId,
                answer: ans,
                question_type: q.question_type,
                id_question: q.id_question,
                question: q.question_label,
              });

              options = [];
              q.choices.forEach((op) => {
                if (q.answer.includes(op.choice_label)) {
                  options.push(
                    <FormControlLabel
                      index={q.id_question}
                      control={
                        <Checkbox
                          value={op.choice_label}
                          defaultChecked
                          onChange={(event) => {
                            onCheckbox(
                              event,
                              q.question_type,
                              q.id_question,
                              q.question_label
                            );
                          }}
                        />
                      }
                      label={op.choice_label}
                    />
                  );
                } else {
                  options.push(
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={op.choice_label}
                          onChange={(event) => {
                            onCheckbox(
                              event,
                              q.question_type,
                              q.id_question,
                              q.question_label
                            );
                          }}
                        />
                      }
                      label={op.choice_label}
                    />
                  );
                }
              });
              questions.push(
                <FormControl component="fieldset">
                  {q.question_description && (
                    <label className={"questionTitle"}>
                      <b>{q.question_description}</b>
                    </label>
                  )}
                  <FormLabel component="span" sx={{ whiteSpace: "pre-line" }}>
                    {q.question_label}
                  </FormLabel>
                  <FormGroup>{options}</FormGroup>
                </FormControl>
              );

              break;
            case "checkbox_columns":
              options = [];
              q.choices.forEach((op) => {
                options.push(
                  <FormControlLabel
                    index={q.id_question}
                    control={<Checkbox />}
                    label={op.choice_label}
                  />
                );
              });
              questions.push(
                <FormControl component="fieldset">
                  {q.question_description && (
                    <label className={"questionTitle"}>
                      <b>{q.question_description}</b>
                    </label>
                  )}
                  <FormLabel component="span" sx={{ whiteSpace: "pre-line" }}>
                    {q.question_label}
                  </FormLabel>
                  <FormGroup className="checkbox-columns">{options}</FormGroup>
                </FormControl>
              );
              break;
            case "select":
              questions.push(
                <>
                  {q.question_title && (
                    <label className={"questionTitle"}>
                      <b>{q.question_title}</b>
                    </label>
                  )}
                  <Dropdown
                    id={q.id_question}
                    label={q.question_label}
                    options={q.choices}
                    selectCallback={singleCallback}
                    userData={{
                      id: userId,
                      block: blockId,
                    }}
                    answer={q.answer}
                    currentAnswers={formAnswers.current}
                  />
                </>
              );
              break;
            case "text_group":
              questions.push(
                <TextGroup
                  min={q.min}
                  max={q.max}
                  id={q.id_question}
                  label={q.question_label}
                />
              );
              break;
            case "table":
              questions.push(
                <TableInput
                  answer={q.answer}
                  rows={q.rows}
                  callback={singleCallback}
                  userData={{
                    id: userId,
                    block: blockId,
                    id_question: q.id_question,
                    question: q.question_label,
                  }}
                />
              );
              break;
            case "checkbox_group":
              questions.push(
                <CheckboxGroup
                  limit={q.max}
                  list={q.list}
                  callback={singleCallback}
                  answer={q.answer}
                  userData={{
                    id: userId,
                    block: blockId,
                    id_question: q.id_question,
                    question: q.id_question,
                  }}
                />
              );
              break;
            case "cono_futuro":
              questions.push(
                <ConodeFuturo
                  data={q.answer ? JSON.parse(q.answer) : {
                    Puesto: "",
                    EscenariosAltamenteDivergentes: {
                      1: "",
                    },
                    EscenariosDirectos: {
                      1: "",
                    },
                    EscenariosAdyacentes: {
                      1: "",
                    },
                    EscenariosAdyacentesII: {
                      1: "",
                    },
                  }}
                  onSave={(newData) => {
                    onAnswer(
                      { target: { value: JSON.stringify(newData) } },
                      q.question_type,
                      q.id_question,
                      q.question_label
                    );
                  }}
                />
              );
              break;
            default:
              questions.push(<p>Unknown question type: {q.question_type}</p>);
              break;
          }
        }
      } else if (q !== null) {
        let options;
        switch (q.question_type) {
          case "radio_single_choice":
            options = [];
            q.choices.forEach((op) => {
              if (op.category_dependency_input !== null) {
                // Your existing checks and logs
                if (
                  formAnswersState.find(
                    (element) =>
                      element.id_question === op.category_dependency_input
                  )?.answer !== op.cateogry_dependency_answer
                ) {
                  return;
                }
              }

              options.push(
                <FormControlLabel
                  index={q.id_question}
                  control={<Radio />}
                  label={op.choice_label}
                  value={op.choice_label}
                />
              );
            });

            if (options.length === 0) {
              options.push(
                <FormControlLabel
                  index={q.id_question}
                  control={<Radio />}
                  label={"Selecciona un objetivo para desplegar las opciones."}
                  value={"Selecciona un objetivo para desplegar las opciones."}
                  disabled
                />
              );
            }
            questions.push(
              <FormControl component="fieldset">
                {q.question_description && (
                  <label className={"questionTitle"}>
                    <b>{q.question_description}</b>
                  </label>
                )}
                <FormLabel component="span" sx={{ whiteSpace: "pre-line" }}>
                  {q.question_label}
                </FormLabel>
                <RadioGroup
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label
                    )
                  }
                >
                  {options}
                </RadioGroup>
              </FormControl>
            );
            break;
          case "text":
            questions.push(
              <FormControl>
                {q.question_title && (
                  <label className={"questionTitle"}>
                    <b>{q.question_title}</b>
                  </label>
                )}
                {q.question_description && (
                  <label className={"questionTitle"}>
                    <b>{q.question_description}</b>
                  </label>
                )}
                <FormLabel component="span" sx={{ whiteSpace: "pre-line" }}>
                  {q.question_label}
                </FormLabel>
                <TextField
                  id={q.id_question}
                  placeholder={
                    q.question_placeholder
                      ? q.question_placeholder
                      : "Escribe aquí..."
                  }
                  variant="outlined"
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label,
                      q.key_validation
                    )
                  }
                  defaultValue={""}
                />
              </FormControl>
            );
            break;
          case "text-small":
            questions.push(
              <FormControl sx={{ width: "40ch" }}>
                {q.question_title && (
                  <label className={"questionTitle"}>
                    <b>{q.question_title}</b>
                  </label>
                )}
                {q.question_description && (
                  <label className={"questionTitle"}>
                    <b>{q.question_description}</b>
                  </label>
                )}
                <FormLabel component="span" sx={{ whiteSpace: "pre-line" }}>
                  {q.question_label}
                </FormLabel>
                <TextField
                  id={q.id_question}
                  placeholder={
                    q.question_placeholder
                      ? q.question_placeholder
                      : "Escribe aquí..."
                  }
                  variant="outlined"
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label,
                      q.key_validation
                    )
                  }
                  defaultValue={""}
                />
              </FormControl>
            );
            break;
          case "text-large":
            questions.push(
              <FormControl>
                {q.question_title && (
                  <label className={"questionTitle"}>
                    <b>{q.question_title}</b>
                  </label>
                )}
                {q.question_description && (
                  <label className={"questionTitle"}>
                    <b>{q.question_description}</b>
                  </label>
                )}
                <FormLabel component="span" sx={{ whiteSpace: "pre-line" }}>
                  {q.question_label}
                </FormLabel>
                <TextField
                  id={q.id_question}
                  placeholder={
                    q.question_placeholder
                      ? q.question_placeholder
                      : "Escribe aquí..."
                  }
                  variant="outlined"
                  multiline
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label,
                      q.key_validation
                    )
                  }
                  defaultValue={""}
                />
              </FormControl>
            );
            break;
          case "textarea":
            questions.push(
              <FormControl>
                {q.question_title && (
                  <label className={"questionTitle"}>
                    <b>{q.question_title}</b>
                  </label>
                )}
                {q.question_description && (
                  <label className={"questionTitle"}>
                    <b>{q.question_description}</b>
                  </label>
                )}
                <FormLabel component="span" sx={{ whiteSpace: "pre-line" }}>
                  {q.question_label}
                </FormLabel>
                <TextField
                  id={q.id_question}
                  placeholder={
                    q.question_placeholder
                      ? q.question_placeholder
                      : "Escribe aquí..."
                  }
                  variant="outlined"
                  multiline
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label,
                      q.key_validation
                    )
                  }
                  defaultValue={""}
                />
              </FormControl>
            );
            break;
          case "file_upload":
            questions.push(
              <FormControl>
                {q.question_description && (
                  <label className={"questionTitle"}>
                    <b>{q.question_description}</b>
                  </label>
                )}
                <FormLabel component="span" sx={{ whiteSpace: "pre-line" }}>
                  {q.question_label}
                </FormLabel>
                <FileUpload
                  fileCallback={singleCallback}
                  userData={{
                    id: userId,
                    block: blockId,
                    id_question: q.id_question,
                    question: q.question_label,
                  }}
                />
              </FormControl>
            );
            break;
          case "radio_range":
            if (q?.choices) {
              questions.push(
                <FormControl>
                  {q.question_description && (
                    <label className={"questionTitle"}>
                      <b>{q.question_description}</b>
                    </label>
                  )}
                  <FormLabel>{q.question_label}</FormLabel>
                  <RadioGroup
                    row
                    defaultValue={+q.answer}
                    onChange={(event) =>
                      onAnswer(
                        event,
                        q.question_type,
                        q.id_question,
                        q.question_label
                      )
                    }
                  >
                    {q.choices.map((choice, i) => (
                      <FormControlLabel
                        key={choice.id}
                        value={choice.choice_label}
                        control={<Radio />}
                        label={choice.choice_label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              );
              break;
            } else {
              if (q.length === 5) {
                questions.push(
                  <FormControl>
                    <FormLabel>{q.question_label}</FormLabel>
                    <RadioGroup
                      row
                      defaultValue={+q.answer}
                      onChange={(event) =>
                        onAnswer(
                          event,
                          q.question_type,
                          q.id_question,
                          q.question_label
                        )
                      }
                    >
                      {[...Array(5)].map((_, i) => (
                        <FormControlLabel
                          key={i}
                          value={i + 1}
                          control={<Radio />}
                          label={i + 1}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                );
              } else {
                questions.push(
                  <FormControl>
                    <FormLabel>{q.question_label}</FormLabel>
                    <RadioGroup
                      row
                      defaultValue={+q.answer}
                      onChange={(event) =>
                        onAnswer(
                          event,
                          q.question_type,
                          q.id_question,
                          q.question_label
                        )
                      }
                    >
                      {[...Array(10)].map((_, i) => (
                        <FormControlLabel
                          key={i}
                          value={i + 1}
                          control={<Radio />}
                          label={i + 1}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                );
              }
            }

            break;
          case "checkbox_multiple_choice":
            options = [];
            q.choices.forEach((op) => {
              options.push(
                <FormControlLabel
                  index={q.id_question}
                  control={
                    <Checkbox
                      value={op.choice_label}
                      onChange={(event) => {
                        onCheckbox(
                          event,
                          q.question_type,
                          q.id_question,
                          q.question_label
                        );
                      }}
                    />
                  }
                  label={op.choice_label}
                />
              );
            });
            questions.push(
              <FormControl component="fieldset">
                {q.question_description && (
                  <label className={"questionTitle"}>
                    <b>{q.question_description}</b>
                  </label>
                )}
                <FormLabel component="span" sx={{ whiteSpace: "pre-line" }}>
                  {q.question_label}
                </FormLabel>
                <FormGroup>{options}</FormGroup>
              </FormControl>
            );
            break;
          case "checkbox_columns":
            options = [];
            q.choices.forEach((op) => {
              options.push(
                <FormControlLabel
                  index={q.id_question}
                  control={<Checkbox />}
                  label={op.choice_label}
                />
              );
            });
            questions.push(
              <FormControl component="fieldset">
                {q.question_description && (
                  <label className={"questionTitle"}>
                    <b>{q.question_description}</b>
                  </label>
                )}
                <FormLabel component="span" sx={{ whiteSpace: "pre-line" }}>
                  {q.question_label}
                </FormLabel>
                <FormGroup className="checkbox-columns">{options}</FormGroup>
              </FormControl>
            );
            break;
          case "select":
            questions.push(
              <>
                {q.question_description && (
                  <label className={"questionTitle"}>
                    <b>{q.question_description}</b>
                  </label>
                )}

                {q.question_title && (
                  <label className={"questionTitle"}>
                    <b>{q.question_title}</b>
                  </label>
                )}
                <Dropdown
                  id={q.id_question}
                  label={q.question_label}
                  options={q.choices}
                  selectCallback={singleCallback}
                  userData={{
                    id: userId,
                    block: blockId,
                  }}
                  currentAnswers={formAnswers.current}
                />
              </>
            );
            break;
          case "text_group":
            questions.push(
              <TextGroup
                min={q.min}
                max={q.max}
                id={q.id_question}
                label={q.question_label}
              />
            );
            break;
          case "table":
            questions.push(
              <TableInput
                rows={q.rows}
                callback={singleCallback}
                userData={{
                  id: userId,
                  block: blockId,
                  id_question: q.id_question,
                  question: q.question_label,
                }}
              />
            );
            break;
          case "checkbox_group":
            questions.push(
              <CheckboxGroup
                limit={q.max}
                list={q.list}
                callback={singleCallback}
                userData={{
                  id: userId,
                  block: blockId,
                  id_question: q.id_question,
                  question: q.id_question,
                }}
              />
            );
            break;
          case "cono_futuro":
            questions.push(
              <ConodeFuturo
                data={
                  q.answer
                    ? JSON.parse(q.answer)
                    : {
                      Puesto: "",
                      EscenariosAltamenteDivergentes: {
                        1: "",
                      },
                      EscenariosDirectos: {
                        1: "",
                      },
                      EscenariosAdyacentes: {
                        1: "",
                      },
                      EscenariosAdyacentesII: {
                        1: "",
                      },
                    }
                }
                onSave={(newData) => {
                  onAnswer(
                    { target: { value: JSON.stringify(newData) } },
                    q.question_type,
                    q.id_question,
                    q.question_label || "Cono de Futuro"
                  );
                }}
              />
            );
            break;
          default:
            questions.push(<p>Unknown question type: {q.question_type}</p>);
            break;
        }
      }
    });
  /*} else {
    props.survey.forEach((q) => {
      if (q != null) {
        let options;
        switch (q.question_type) {
          case "radio_single_choice":
            options = [];
            q.choices.forEach((op) => {
              if (op.category_dependency_input !== null) {
                // Your existing checks and logs
                if (
                  formAnswersState.find(
                    (element) =>
                      element.id_question === op.category_dependency_input
                  )?.answer !== op.cateogry_dependency_answer
                ) {
                  return;
                }
              }

              options.push(
                <FormControlLabel
                  index={q.id_question}
                  control={<Radio />}
                  label={op.choice_label}
                  value={op.choice_label}
                />
              );
            });

            if (options.length === 0) {
              options.push(
                <FormControlLabel
                  index={q.id_question}
                  control={<Radio />}
                  label={"Selecciona un objetivo para desplegar las opciones."}
                  value={"Selecciona un objetivo para desplegar las opciones."}
                  disabled
                />
              );
            }
            questions.push(
              <FormControl component="fieldset">
                 <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
                <RadioGroup
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label
                    )
                  }
                >
                  {options}
                </RadioGroup>
              </FormControl>
            );
            break;
          case "text":
            questions.push(
              <FormControl>
                {q.question_title && (
                  <label className={"questionTitle"}>
                    <b>{q.question_title}</b>
                  </label>
                )}
                 <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
                <TextField
                  id={q.id_question}
                  placeholder={
                    q.question_placeholder
                      ? q.question_placeholder
                      : "Escribe aquí..."
                  }
                  variant="outlined"
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label,
                      q.key_validation
                    )
                  }
                  defaultValue={""}
                />
              </FormControl>
            );
            break;
          case "text-small":
            questions.push(
              <FormControl sx={{ width: "40ch" }}>
                {q.question_title && (
                  <label className={"questionTitle"}>
                    <b>{q.question_title}</b>
                  </label>
                )}
                 <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
                <TextField
                  id={q.id_question}
                  placeholder={
                    q.question_placeholder
                      ? q.question_placeholder
                      : "Escribe aquí..."
                  }
                  variant="outlined"
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label,
                      q.key_validation
                    )
                  }
                  defaultValue={""}
                />
              </FormControl>
            );
            break;
          case "text-large":
            questions.push(
              <FormControl>
                {q.question_title && (
                  <label className={"questionTitle"}>
                    <b>{q.question_title}</b>
                  </label>
                )}
                 <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
                <TextField
                  id={q.id_question}
                  placeholder={
                    q.question_placeholder
                      ? q.question_placeholder
                      : "Escribe aquí..."
                  }
                  variant="outlined"
                  multiline
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label,
                      q.key_validation
                    )
                  }
                  defaultValue={""}
                />
              </FormControl>
            );
            break;
          case "textarea":
            questions.push(
              <FormControl>
                {q.question_title && (
                  <label className={"questionTitle"}>
                    <b>{q.question_title}</b>
                  </label>
                )}
                 <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
                <TextField
                  id={q.id_question}
                  placeholder={
                    q.question_placeholder
                      ? q.question_placeholder
                      : "Escribe aquí..."
                  }
                  variant="outlined"
                  multiline
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label,
                      q.key_validation
                    )
                  }
                  defaultValue={""}
                />
              </FormControl>
            );
            break;
          case "file_upload":
            questions.push(
              <FileUpload
                fileCallback={singleCallback}
                userData={{
                  id: userId,
                  block: blockId,
                  id_question: q.id_question,
                  question: q.question_label,
                }}
              />
            );
            break;
          case "radio_range":
            if (q?.choices) {
              questions.push(
                <FormControl>
                  <FormLabel>{q.question_label}</FormLabel>
                  <RadioGroup
                    row
                    defaultValue={+q.answer}
                    onChange={(event) =>
                      onAnswer(
                        event,
                        q.question_type,
                        q.id_question,
                        q.question_label
                      )
                    }
                  >
                    {q.choices.map((choice, i) => (
                      <FormControlLabel
                        key={choice.id}
                        value={choice.choice_label}
                        control={<Radio />}
                        label={choice.choice_label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              );
              break;
            } else {
              if (q.length === 5) {
                questions.push(
                  <FormControl>
                    <FormLabel>{q.question_label}</FormLabel>
                    <RadioGroup
                      row
                      defaultValue={+q.answer}
                      onChange={(event) =>
                        onAnswer(
                          event,
                          q.question_type,
                          q.id_question,
                          q.question_label
                        )
                      }
                    >
                      {[...Array(5)].map((_, i) => (
                        <FormControlLabel
                          key={i}
                          value={i + 1}
                          control={<Radio />}
                          label={i + 1}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                );
              } else {
                questions.push(
                  <FormControl>
                    <FormLabel>{q.question_label}</FormLabel>
                    <RadioGroup
                      row
                      defaultValue={+q.answer}
                      onChange={(event) =>
                        onAnswer(
                          event,
                          q.question_type,
                          q.id_question,
                          q.question_label
                        )
                      }
                    >
                      {[...Array(10)].map((_, i) => (
                        <FormControlLabel
                          key={i}
                          value={i + 1}
                          control={<Radio />}
                          label={i + 1}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                );
              }
            }

            break;
          case "checkbox_multiple_choice":
            options = [];
            q.choices.forEach((op) => {
              options.push(
                <FormControlLabel
                  index={q.id_question}
                  control={
                    <Checkbox
                      value={op.choice_label}
                      onChange={(event) => {
                        onCheckbox(
                          event,
                          q.question_type,
                          q.id_question,
                          q.question_label
                        );
                      }}
                    />
                  }
                  label={op.choice_label}
                />
              );
            });
            questions.push(
              <FormControl component="fieldset">
                 <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
                <FormGroup>{options}</FormGroup>
              </FormControl>
            );
            break;
          case "checkbox_columns":
            options = [];
            q.choices.forEach((op) => {
              options.push(
                <FormControlLabel
                  index={q.id_question}
                  control={<Checkbox />}
                  label={op.choice_label}
                />
              );
            });
            questions.push(
              <FormControl component="fieldset">
                 <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
                <FormGroup className="checkbox-columns">{options}</FormGroup>
              </FormControl>
            );
            break;
          case "select":
            questions.push(
              <>
                {q.question_title && (
                  <label className={"questionTitle"}>
                    <b>{q.question_title}</b>
                  </label>
                )}
                <Dropdown
                  id={q.id_question}
                  label={q.question_label}
                  options={q.choices}
                  selectCallback={singleCallback}
                  userData={{
                    id: userId,
                    block: blockId,
                  }}
                  currentAnswers={formAnswers.current}
                />
              </>
            );
            break;
          case "text_group":
            questions.push(
              <TextGroup
                min={q.min}
                max={q.max}
                id={q.id_question}
                label={q.question_label}
              />
            );
            break;
          case "table":
            questions.push(
              <TableInput
                rows={q.rows}
                callback={singleCallback}
                userData={{
                  id: userId,
                  block: blockId,
                  id_question: q.id_question,
                  question: q.question_label,
                }}
              />
            );
            break;
          case "checkbox_group":
            questions.push(
              <CheckboxGroup
                limit={q.max}
                list={q.list}
                callback={singleCallback}
                userData={{
                  id: userId,
                  block: blockId,
                  id_question: q.id_question,
                  question: q.id_question,
                }}
              />
            );
            break;
          default:
            questions.push(<p>Unknown question type: {q.question_type}</p>);
            break;
        }
      }
    });
  }*/

  return (
    <ThemeProvider theme={theme}>
      <div className="form">
        <form>{questions}</form>
        <div className="error">
          {error &&
            "La clave del mentor es erronea, por favor verifica la clave con tu mento."}
        </div>
      </div>
    </ThemeProvider>
  );
}
