import { FormControl } from "@mui/material";
import "./TableInput.css";
import Dropdown from "../Dropdown/Dropdown";

export default function TableInput(props) {
  const heads = [];
  const rows = [];
  const cols = props.rows.length - 1;
  const ansRows = [];
  const ansCols = [];
  const ansObj = {
    block_id: props.userData.block,
    user_id: props.userData.id,
    answer: [],
    question_type: "table",
    id_question: props.userData.id_question,
    question: props.userData.question,
  };

  const tableCallback = (event, rowIndex, colIndex) => {
    // Check if current cell has an input already
    const index = ansObj.answer.findIndex(
      (element) => element.index === `${rowIndex}${colIndex}`
    );
    if (index > -1) {
      // Cell already has a value, replace
      ansObj.answer[index].answer = event.target.value;
    } else {
      // New input
      ansObj.answer.push({
        index: `${rowIndex}${colIndex}`,
        row: ansRows[rowIndex],
        col: ansCols[colIndex],
        answer: event.target.value,
      });
    }

    /*console.log({
      index: `${rowIndex}${colIndex}`,
      row: ansRows[rowIndex],
      col: ansCols[colIndex],
      answer: event.target.value,
    });*/

    /*console.log("AnsObj length: " + ansObj.answer.length)
    console.log(ansObj.answer)*/

    if (ansObj.answer.length === ansLength) {
      // Only use callback when the answer array is complete
      props.callback(ansObj);
    }
  };

  /**
   * Answer object
    row: rows[0].columns[index].column_header
    column: rows[index].row_header
    answer: input
   */

  props.rows.forEach((el, index) => {
    if(index > 0){
      heads.push(<th>{el.row_header}</th>);
    }
    ansCols.push(el.row_header);
  });

  if (props.answer) {
    ansObj.answer = JSON.parse(props.answer);
    //console.log("answers found");
    props.rows[0].columns.forEach((col, index) => {
      const fields = [];
        for (let i = 0; i < cols; i++) {
          const inputIndex = JSON.parse(props.answer).findIndex(element => element.index === `${index}${i}`);
          //console.log(JSON.parse(props.answer)[inputIndex].answer);
          fields.push(
            <td>
              <input
                type="text"
                placeholder="Escribe aquí..."
                onChange={(event) => {
                  tableCallback(event, index, i);
                }}
                defaultValue={JSON.parse(props.answer)[inputIndex].answer}
              />
            </td>
          );
        }
      
      ansRows.push(col.column_header);
      rows.push(
        <tr>
          <th>{col.column_header}</th>
          {fields}
        </tr>
      );
    });
  } else {
    console.log("no answers");
    props.rows[0].columns.forEach((col, index) => {
      const fields = [];
      if (col.type === "text" || col.type === "label") {
        for (let i = 0; i < cols; i++) {
          fields.push(
            <td>
              <input
                type="text"
                placeholder="Escribe aquí..."
                onChange={(event) => {
                  tableCallback(event, index, i);
                }}
              />
            </td>
          );
        }
      } else if (col.type === "select") {
        for (let i = 0; i < cols; i++) {
          fields.push(
            <td>
              <Dropdown options={col.choices} label="" />
            </td>
          );
        }
      }
      ansRows.push(col.column_header);
      rows.push(
        <tr>
          <th>{col.column_header}</th>
          {fields}
        </tr>
      );
    });
  }

  const ansLength = ansRows.length * cols

  /*
  console.log("Heads");
  console.log(heads);
  console.log("---");
  console.log("Rows");
  console.log(rows);
  console.log("---");
  */

  return (
    <FormControl>
      {props.answer && (
        <table>
          <thead>
            <tr>
            <td></td>
              {heads}
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      )}

      {!props.answer && (
        <table>
          <thead>
            <tr>
              <td></td>
              {heads}
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      )}
    </FormControl>
  );
}
