import React, { useState } from "react";
import ConoFuturo from "./ConoFuturo"; 
import styles from "./ConodeFuturo.module.css";

const ConodeFuturo = ({ data, onSave }) => {
    const [visibleScenario, setVisibleScenario] = useState("directos");

    return (
        <div className={styles.container}>
            <div className = {styles.subcontainer}>
                {["directos", "adyacentes", "adyacentesii", "altamente"].map((tipo, index) => {
                    const isActive = visibleScenario === tipo;
                    const labels = {
                        directos: "Directo",
                        adyacentes: "Adyacente",
                        adyacentesii: "Adyacente II",
                        altamente: "Altamente divergente",
                    };

                    return (
                        <button
                            key={index}
                            onClick={(e) => {
                                setVisibleScenario(tipo);
                                e.preventDefault();
                            }}
                            className={styles.buttons}
                            style={{
                                backgroundColor: isActive ? "#005280" : "#71ABCC",
                            }}
                        >
                            {labels[tipo]}

                            {isActive && (
                                <div className={styles.activeButton}></div>
                            )}
                        </button>
                    );
                })}
            </div>
                
            <div className = {styles.innerContainer}>
                <div className={styles.subInnerContainer}>
                    <ConoFuturo data={data} onSave={(newData) => {
                        onSave(newData);
                    }} visibleScenario={visibleScenario} />
                </div>
            </div>
        </div>
    );
};

export default ConodeFuturo;
