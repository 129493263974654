import React from "react";
import "./Escenarios.css";
import ReactFlow, {
  ReactFlowProvider,
  Panel,
  useNodesState,
  useEdgesState,
  useReactFlow,
  MarkerType,
} from "reactflow";
import "reactflow/dist/style.css";

function Escenarios({escenariosData}) {
  const nodes = [
    {
      id: "root",
      position: { x: 0, y: 350 },
      data: { label: "Tu puesto actual" },
      sourcePosition: "right",
      targetPosition: "right",
    },
    {
      id: "root-label",
      position: { x: 0, y: 385 },
      data: { label: "Tú actualmente" },
      sourcePosition: "none",
      targetPosition: "none",
      style: {backgroundColor: "transparent", color: "white", border: "none"},
      className: "node-label"
    },
    {
      id: "divergent-label",
      position: { x: 200, y: 0 },
      data: { label: "Escenarios altamente divergentes" },
      sourcePosition: "none",
      targetPosition: "none",
      style: {backgroundColor: "transparent", color: "rgb(229,146,186)", border: "none"},
      className: "node-label"
    },
    {
      id: "direct-label",
      position: { x: 200, y: 315 },
      data: { label: "Escenarios directos" },
      sourcePosition: "none",
      targetPosition: "none",
      style: {backgroundColor: "transparent", color: "rgb(38,208,124)", border: "none"},
      className: "node-label"
    },
    {
      id: "firstAdj-label",
      position: { x: 200, y: 165 },
      data: { label: "Escenarios adyacentes" },
      sourcePosition: "none",
      targetPosition: "none",
      style: {backgroundColor: "transparent", color: "rgb(164,203,244)", border: "none"},
      className: "node-label"
    },
    {
      id: "secondAdj-label",
      position: { x: 200, y: 465 },
      data: { label: "Escenarios adyacentes" },
      sourcePosition: "none",
      targetPosition: "none",
      style: {backgroundColor: "transparent", color: "rgb(164,203,244)", border: "none"},
      className: "node-label"
    },
  ];
  const edges = [
    {
      id: `root-direct`,
      source: `root`,
      target: `direct-0`,
      style: { strokeWidth: "4px", stroke: "white" },
    },
    {
      id: `root-firstAdj`,
      source: `root`,
      target: `firstAdj-0`,
      style: { strokeWidth: "4px", stroke: "white" },
    },
    {
      id: `root-secondAdj`,
      source: `root`,
      target: `secondAdj-0`,
      style: { strokeWidth: "4px", stroke: "white" },
    },
    {
      id: `root-divergent`,
      source: `root`,
      target: `divergent-0`,
      style: { strokeWidth: "4px", stroke: "white" },
    },
  ];

  // Directo
  const directFuture = [];
  // Ambicioso Directo
  const dirAmbFuture = [];
  // Adyacente 1
  const firstAdj = [];
  // Ambicioso Adyacente 1
  const firstAmbAdj = [];
  // Adyacente 2
  const secondAdj = [];
  // Ambicioso Adyacente 2
  const secondAmbAdj = [];
  // Divergente
  const divergent = [];
  // Divergente Ambicioso
  const divergentAmb = [];

  // Select and order direct + ambitious direct scenarios
  for (let i = 0; i < escenariosData.answersDirectFuture.length; i++) {
    // sample: "Puesto directo 1" or "Puesto ambicioso directo 2"
    // The second word determines the type and the last word the order
    const question = escenariosData.answersDirectFuture[i].question.split(" ");
    const type = question[1];

    if (type === "directo") {
      directFuture[+question[question.length - 1] - 1] =
        escenariosData.answersDirectFuture[i];
    } else {
      dirAmbFuture[+question[question.length - 1] - 1] =
        escenariosData.answersDirectFuture[i];
    }
  }

  // Create nodes and edges for direct scenarios
  for (let i = 0; i < directFuture.length; i++) {
    nodes.push({
      id: `direct-${i}`,
      position: { x: (i + 1) * 200, y: 350 },
      data: { label: directFuture[i]?.answer },
      sourcePosition: "right",
      targetPosition: "left",
      style: { backgroundColor: "rgb(38,208,124)" },
    });
    if (i > 0) {
      edges.push({
        id: `direct-${i - 1}-${i}`,
        source: `direct-${i - 1}`,
        target: `direct-${i}`,
        sourcePosition: "right",
        targetPosition: "left",
        style: { strokeWidth: "4px", stroke: "white" },
      });
    }
  }

  // Create nodes and edges for ambitious direct scenarios
  // Link first ambitious node to last direct node
  nodes.push({
    id: `dirAmb-${0}`,
    position: { x: (directFuture.length + 1) * 200, y: 350 },
    data: { label: dirAmbFuture[0]?.answer },
    sourcePosition: "right",
    targetPosition: "left",
    style: { backgroundColor: "rgb(239,206,42)" },
  });
  edges.push({
    id: `dirAmb-0-0`,
    source: `direct-${directFuture.length - 1}`,
    target: `dirAmb-0`,
    style: { strokeWidth: "4px", stroke: "white" },
    markerEnd: { type: MarkerType.Arrow, color: "white" },
  });

  // Create the rest of the ambitious direct nodes
  for (let i = 1; i < dirAmbFuture.length; i++) {
    nodes.push({
      id: `dirAmb-${i}`,
      position: { x: (directFuture.length + i + 1) * 200, y: 350 },
      data: { label: dirAmbFuture[i]?.answer },
      sourcePosition: "right",
      targetPosition: "left",
      style: { backgroundColor: "rgb(239,206,42)" },
    });

    edges.push({
      id: `dirAmb-${i - 1}-${i}`,
      source: `dirAmb-${i - 1}`,
      target: `dirAmb-${i}`,
      style: { strokeWidth: "4px", stroke: "rgb(239,206,42)" },
      animated: true,
    });
  }

  // Select and order first adjacent + first ambitious adjacent scenarios
  for (let i = 0; i < escenariosData.answersAdjacentFuture.length; i++) {
    // sample: "Puesto adyacente 1" or "Puesto ambicioso adyacente 2"
    // The second word determines the type and the last word the order
    const question = escenariosData.answersAdjacentFuture[i].question.split(" ");
    const type = question[1];

    if (type === "adyacente") {
      firstAdj[+question[question.length - 1] - 1] =
        escenariosData.answersAdjacentFuture[i];
    } else {
      firstAmbAdj[+question[question.length - 1] - 1] =
        escenariosData.answersAdjacentFuture[i];
    }
  }

  // Create nodes and edges for first adjacent scenarios
  for (let i = 0; i < firstAdj.length; i++) {
    nodes.push({
      id: `firstAdj-${i}`,
      position: { x: (i + 1) * 200, y: 200 },
      data: { label: firstAdj[i]?.answer },
      sourcePosition: "right",
      targetPosition: "left",
      style: { backgroundColor: "rgb(164,203,244)" },
    });
    if (i > 0) {
      edges.push({
        id: `firstAdj-${i - 1}-${i}`,
        source: `firstAdj-${i - 1}`,
        target: `firstAdj-${i}`,
        style: { strokeWidth: "4px", stroke: "white" },
      });
    }
  }

  // Create nodes and edges for first ambitious adjacent scenarios
  // Link first ambitious node to last adjacent node
  nodes.push({
    id: `firstAmbAdj-${0}`,
    position: { x: (firstAdj.length + 1) * 200, y: 200 },
    data: { label: firstAmbAdj[0]?.answer },
    sourcePosition: "right",
    targetPosition: "left",
    style: { backgroundColor: "rgb(239,206,42)" },
  });
  edges.push({
    id: `firstAmbAdj-0-0`,
    source: `firstAdj-${firstAdj.length - 1}`,
    target: `firstAmbAdj-0`,
    style: { strokeWidth: "4px", stroke: "white" },
  });

  // Create the rest of the ambitious direct nodes
  for (let i = 1; i < firstAmbAdj.length; i++) {
    nodes.push({
      id: `firstAmbAdj-${i}`,
      position: { x: (firstAdj.length + i + 1) * 200, y: 200 },
      data: { label: firstAmbAdj[i]?.answer },
      sourcePosition: "right",
      targetPosition: "left",
      style: { backgroundColor: "rgb(239,206,42)" },
    });

    edges.push({
      id: `firstAmbAdj-${i - 1}-${i}`,
      source: `firstAmbAdj-${i - 1}`,
      target: `firstAmbAdj-${i}`,
      style: { strokeWidth: "4px", stroke: "white" },
    });
  }

  // Select and order second adjacent + second ambitious adjacent scenarios
  for (let i = 0; i < escenariosData.answersSecondAdjacentFuture.length; i++) {
    // sample: "Puesto adyacente 1" or "Puesto ambicioso adyacente 2"
    // The second word determines the type and the last word the order
    const question =
      escenariosData.answersSecondAdjacentFuture[i].question.split(" ");
    const type = question[1];

    if (type === "adyacente") {
      secondAdj[+question[question.length - 1] - 1] =
        escenariosData.answersSecondAdjacentFuture[i];
    } else {
      secondAmbAdj[+question[question.length - 1] - 1] =
        escenariosData.answersSecondAdjacentFuture[i];
    }
  }

  // Create nodes and edges for second adjacent scenarios
  for (let i = 0; i < secondAdj.length; i++) {
    nodes.push({
      id: `secondAdj-${i}`,
      position: { x: (i + 1) * 200, y: 500 },
      data: { label: secondAdj[i]?.answer },
      sourcePosition: "right",
      targetPosition: "left",
      style: { backgroundColor: "rgb(164,203,244)" },
    });
    if (i > 0) {
      edges.push({
        id: `secondAdj-${i - 1}-${i}`,
        source: `secondAdj-${i - 1}`,
        target: `secondAdj-${i}`,
        style: { strokeWidth: "4px", stroke: "white" },
      });
    }
  }

  // Create nodes and edges for second ambitious adjacent scenarios
  // Link first ambitious node to last adjacent node
  nodes.push({
    id: `secondAmbAdj-${0}`,
    position: { x: (secondAdj.length + 1) * 200, y: 500 },
    data: { label: secondAmbAdj[0]?.answer },
    sourcePosition: "right",
    targetPosition: "left",
    style: { backgroundColor: "rgb(239,206,42)" },
  });
  edges.push({
    id: `secondAmbAdj-0-0`,
    source: `secondAdj-${secondAdj.length - 1}`,
    target: `secondAmbAdj-0`,
    style: { strokeWidth: "4px", stroke: "white" },
  });

  // Create the rest of the second ambitious adjacent nodes
  for (let i = 1; i < secondAmbAdj.length; i++) {
    nodes.push({
      id: `secondAmbAdj-${i}`,
      position: { x: (secondAdj.length + i + 1) * 200, y: 500 },
      data: { label: secondAmbAdj[i]?.answer },
      sourcePosition: "right",
      targetPosition: "left",
      style: { backgroundColor: "rgb(239,206,42)" },
    });

    edges.push({
      id: `secondAmbAdj-${i - 1}-${i}`,
      source: `secondAmbAdj-${i - 1}`,
      target: `secondAmbAdj-${i}`,
      style: { strokeWidth: "4px", stroke: "white" },
    });
  }

  // Select and order divergent + ambitious divergent scenarios
  for (let i = 0; i < escenariosData.answersDivergentFuture.length; i++) {
    // sample: "Puesto altamente divergente 1" or "Puesto ambicioso altamente divergente 2"
    // The second word determines the type and the last word the order
    const question = escenariosData.answersDivergentFuture[i].question.split(" ");
    const type = question[1];

    if (type === "altamente") {
      divergent[+question[question.length - 1] - 1] =
        escenariosData.answersDivergentFuture[i];
    } else {
      divergentAmb[+question[question.length - 1] - 1] =
        escenariosData.answersDivergentFuture[i];
    }
  }

  // Create nodes and edges for divergent scenarios
  for (let i = 0; i < divergent.length; i++) {
    nodes.push({
      id: `divergent-${i}`,
      position: { x: (i + 1) * 200, y: 50 },
      data: { label: divergent[i]?.answer },
      sourcePosition: "right",
      targetPosition: "left",
      style: { backgroundColor: "rgb(229,146,186)" },
    });
    if (i > 0) {
      edges.push({
        id: `divergent-${i - 1}-${i}`,
        source: `divergent-${i - 1}`,
        target: `divergent-${i}`,
        style: { strokeWidth: "4px", stroke: "white" },
        animated: true,
      });
    }
  }

  // Create nodes and edges for ambitious divergent scenarios
  // Link first ambitious node to last divergent node
  nodes.push({
    id: `divergentAmb-${0}`,
    position: { x: (divergent.length + 1) * 200, y: 50 },
    data: { label: divergentAmb[0]?.answer },
    sourcePosition: "right",
    targetPosition: "left",
    style: { backgroundColor: "rgb(239,206,42)" },
  });
  edges.push({
    id: `divergentAmb-0-0`,
    source: `divergent-${divergent.length - 1}`,
    target: `divergentAmb-0`,
    style: { strokeWidth: "4px", stroke: "white" },
    animated: true,
  });

  // Create the rest of the ambitious divergent nodes
  for (let i = 1; i < divergentAmb.length; i++) {
    nodes.push({
      id: `divergentAmb-${i}`,
      position: { x: (divergent.length + i + 1) * 200, y: 50 },
      data: { label: divergentAmb[i]?.answer },
      sourcePosition: "right",
      targetPosition: "left",
      style: { backgroundColor: "rgb(239,206,42)" },
    });

    edges.push({
      id: `divergentAmb-${i - 1}-${i}`,
      source: `divergentAmb-${i - 1}`,
      target: `divergentAmb-${i}`,
      style: { strokeWidth: "4px", stroke: "white" },
      animated: true,
    });
  }

  // Create label for ambitious nodes
  nodes.push({
    id: "divergentAmb-label",
    position: { x: ((divergent.length + divergentAmb.length) * 200), y: 10 },
    data: { label: "Escenarios ambiciosos" },
    sourcePosition: "none",
    targetPosition: "none",
    style: {backgroundColor: "transparent", color: "rgb(239,206,42)", border: "none"},
    className: "node-label"
  },
  {
    id: "directAmb-label",
    position: { x: ((directFuture.length + dirAmbFuture.length) * 200), y: 315 },
    data: { label: "Escenarios ambiciosos" },
    sourcePosition: "none",
    targetPosition: "none",
    style: {backgroundColor: "transparent", color: "rgb(239,206,42)", border: "none"},
    className: "node-label"
  },
  {
    id: "firstAdjAmb-label",
    position: { x: ((firstAdj.length + firstAmbAdj.length) * 200), y: 165 },
    data: { label: "Escenarios ambiciosos" },
    sourcePosition: "none",
    targetPosition: "none",
    style: {backgroundColor: "transparent", color: "rgb(239,206,42)", border: "none"},
    className: "node-label"
  },
  {
    id: "secondAdjAmb-label",
    position: { x: ((secondAdj.length + secondAmbAdj.length) * 200), y: 465 },
    data: { label: "Escenarios ambiciosos" },
    sourcePosition: "none",
    targetPosition: "none",
    style: {backgroundColor: "transparent", color: "rgb(239,206,42)", border: "none"},
    className: "node-label"
  })

  return (
    <div>
      <h3 className="escenarios-title">Escenarios</h3>
      <p className="escenarios-subtitle">
        Descubre los escenarios futuros posibles para tu carrera, basados en tus respuestas. Puedes arrastrar el espacio verde para moverte por los escenarios.
      </p>
      <div className="escenarios-layout">
        <ReactFlow nodes={nodes} edges={edges} />
      </div>
    </div>
  );
}

export default Escenarios;