import styles from '../../../ConoFuturo/ConoFuturoPA/ConoFuturo.module.css'

const Flecha = ({ style, dotted = false, curved = false, length = 100 }) => {
    return (
        <svg
            className={styles.flecha}
            style={{ ...style, overflow: 'visible', width: `${length}px`, height: '29px' }}
            viewBox={`0 0 ${length} 50`}
            preserveAspectRatio="none"
        >
            {curved ? (
                <path
                    d={`M0,26 Q${length / 2},0 ${length - 10},110`}
                    stroke="white"
                    strokeWidth="10"
                    fill="none"
                    strokeDasharray={dotted ? '12,12' : 'none'}
                    strokeLinecap="inherit"
                    markerEnd="url(#arrowhead)"
                />
            ) : (
                <line
                    x1="0" y1="25"
                    x2={length - 10} y2="25"
                    stroke="white"
                    strokeWidth="10"
                    strokeDasharray={dotted ? '12,12' : 'none'}
                    strokeLinecap="inherit"
                    markerEnd="url(#arrowhead)"
                />
            )}
        </svg>
    );
};


export default Flecha;