import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:3501";

let instance = axios.create({
    baseURL: baseUrl + "/promocodes"
});

export const getPromocode = async (promo_code) => {
    try {
        const response = await instance.get(`/get-promocode/${promo_code}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const updatePromocode = async (id) => {
    try {
        const response = await instance.put(`/update-promocode/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}
